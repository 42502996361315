/*
*
*
*
*   CREATED
*   BY
*   BARTMAN
*
*
 */

/*************************************************** CUSTOM HELPERS CLASS *********************************************/

/*---------- SPACING ----------*/


/* Padding Zero */

.p-0 {
    padding: 0!important;
}

/* Padding Top */

.p-t-10 {
    padding-top: 10px!important;
}

.p-t-15 {
    padding-top: 15px!important;
}

.p-t-20 {
    padding-top: 20px!important;
}

.p-t-30 {
    padding-top: 30px!important;
}

.p-t-40 {
    padding-top: 40px!important;
}

.p-t-50 {
    padding-top: 50px!important;
}

/* Padding Bottom */

.p-b-0 {
    padding-bottom: 0!important;
}

.p-b-10 {
    padding-bottom: 10px!important;
}

.p-b-15 {
    padding-bottom: 15px!important;
}

.p-b-20 {
    padding-bottom: 20px!important;
}

.p-b-30 {
    padding-bottom: 30px!important;
}

.p-b-40 {
    padding-bottom: 40px!important;
}

.p-b-50 {
    padding-bottom: 50px!important;
}

/* Padding Left */

.p-l-0 {
    padding-left: 0!important;
}

.p-l-3 {
    padding-left: 3px!important;
}

.p-l-5 {
    padding-left: 5px!important;
}

.p-l-10 {
    padding-left: 10px!important;
}

.p-l-20 {
    padding-left: 20px!important;
}

.p-l-30 {
    padding-left: 30px;
}

.p-l-40 {
    padding-left: 40px;
}

.p-l-50 {
    padding-left: 50px;
}

/* Padding Right */

.p-r-0 {
    padding-right: 0!important;
}

.p-r-5 {
    padding-right: 5px!important;
}

.p-r-10 {
    padding-right: 10px!important;
}

.p-r-20 {
    padding-right: 20px!important;
}

.p-r-30 {
    padding-right: 30px!important;
}

.p-r-40 {
    padding-right: 40px;
}

.p-r-50 {
    padding-right: 50px;
}

/* Padding Left-Right */

.p-l-r-0 {
    padding-left: 0!important;
    padding-right: 0!important;
}

.p-l-r-5 {
    padding-left: 5px!important;
    padding-right: 5px!important;
}

.p-l-r-10 {
    padding-left: 10px!important;
    padding-right: 10px!important;
}

.p-l-r-20 {
    padding-left: 20px!important;
    padding-right: 20px!important;
}

.p-l-r-30 {
    padding-left: 30px!important;
    padding-right: 30px!important;
}

.p-l-r-40 {
    padding-left: 40px;
    padding-right: 40px;
}

.p-l-r-50 {
    padding-left: 50px;
    padding-right: 50px;
}

/* TEXT AREA UTIL */

.textarea-resize-height{
    resize: vertical;
}

.textarea-resize-width{
    resize: horizontal;
}

.textarea-resize-none{
    resize: none;
}