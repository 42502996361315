.hero {
    background-image: url("../../../s/img/landing/landing-page-hero/hero.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 587px;
}

.about {
    background-image: url("../../../s/img/landing/landing-page-about/about.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 550px;
}

/************************************ HERO TEXT ****************************************************************/

.text-hero {
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #ffffff;
    text-shadow: 0 2px 3px #83a0b1;
}

/************************************ LANDING PAGE BACKGROUND GRADIENT *****************************************/

.bg-gradient {
    background-image: linear-gradient(232deg, #51c4cf, #53bbcc 18%, #57a1c3 47%, #5e78b4 84%, #6164ad);
}

/************************************ BENEFITS LIST ************************************************************/

.benefits-list {
    display: flex;
    align-items: stretch;
}

.benefits-list > dl > dt > span {
    font-size: 20px;
    font-weight: 300;
}

.benefits-list > dl > dd > p {
    font-size: 13px;
    font-weight: 500;
}

/************************************** ACCESS LOGO ************************************************************/

.access-header-wrapper {
    display: flex;
    justify-content: center;
}

.access-header-logo {
    min-width: 129px;
    min-height: 35px;
    display: inline-block;

    background-image: url("../../../s/img/logo/logo-with-name.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    text-align: center;
}

/*************************************** AGREE LINK ************************************************************/

.agree-link {
    color: #337ab7;
}