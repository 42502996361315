.sub-icon {
    width: 30px;
    height: 30px;
    border-radius: 1.4px;
    display: inline-block;
}

.sub-icon.rounded-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.sub-icon-fitness-body {
    background-color: #23b7e5;
    background-image: url("../../../s/img/icons/fitness/body.svg");
}

.sub-icon-fitness-dance {
    background-color: #23b7e5;
    background-image: url("../../../s/img/icons/fitness/dance.svg");
}

.sub-icon-fitness-education {
    background-color: #23b7e5;
    background-image: url("../../../s/img/icons/fitness/education.svg");
}

.sub-icon-fitness-group {
    background-color: #23b7e5;
    background-image: url("../../../s/img/icons/fitness/group.svg");
}

.sub-icon-fitness-gym {
    background-color: #23b7e5;
    background-image: url("../../../s/img/icons/fitness/gym.svg");
}

.sub-icon-fitness-kids {
    background-color: #23b7e5;
    background-image: url("../../../s/img/icons/fitness/kids.svg");
}

.sub-icon-fitness-martial {
    background-color: #23b7e5;
    background-image: url("../../../s/img/icons/fitness/martial.svg");
}

.sub-icon-fitness-ondemand {
    background-color: #23b7e5;
    background-image: url("../../../s/img/icons/fitness/ondemand.svg");
}

.sub-icon-fitness-personal {
    background-color: #23b7e5;
    background-image: url("../../../s/img/icons/fitness/personal.svg");
}

.sub-icon-fitness-prenatal {
    background-color: #23b7e5;
    background-image: url("../../../s/img/icons/fitness/prenatal.svg");
}

.sub-icon-fitness-retreats {
    background-color: #23b7e5;
    background-image: url("../../../s/img/icons/fitness/retreats.svg");
}

.sub-icon-fitness-senior {
    background-color: #23b7e5;
    background-image: url("../../../s/img/icons/fitness/senior.svg");
}

.sub-icon-fitness-sports {
    background-color: #23b7e5;
    background-image: url("../../../s/img/icons/fitness/sports.svg");
}

.sub-icon-beauty-aesthetical {
    background-color: #a0a0bf;
    background-image: url("../../../s/img/icons/beauty/aesthetical.svg");
}

.sub-icon-beauty-barber {
    background-color: #a0a0bf;
    background-image: url("../../../s/img/icons/beauty/barber.svg");
}

.sub-icon-beauty-depilation {
    background-color: #a0a0bf;
    background-image: url("../../../s/img/icons/beauty/depilation.svg");
}

.sub-icon-beauty-education {
    background-color: #a0a0bf;
    background-image: url("../../../s/img/icons/beauty/education.svg");
}

.sub-icon-beauty-face {
    background-color: #a0a0bf;
    background-image: url("../../../s/img/icons/beauty/face.svg");
}

.sub-icon-beauty-hair {
    background-color: #a0a0bf;
    background-image: url("../../../s/img/icons/beauty/hair.svg");
}

.sub-icon-beauty-makeup {
    background-color: #a0a0bf;
    background-image: url("../../../s/img/icons/beauty/makeup.svg");
}

.sub-icon-beauty-manicure {
    background-color: #a0a0bf;
    background-image: url("../../../s/img/icons/beauty/manicure.svg");
}

.sub-icon-beauty-ondemand {
    background-color: #a0a0bf;
    background-image: url("../../../s/img/icons/beauty/ondemand.svg");
}

.sub-icon-beauty-skin {
    background-color: #a0a0bf;
    background-image: url("../../../s/img/icons/beauty/skin.svg");
}

.sub-icon-beauty-slimming {
    background-color: #a0a0bf;
    background-image: url("../../../s/img/icons/beauty/slimming.svg");
}

.sub-icon-beauty-tanning {
    background-color: #a0a0bf;
    background-image: url("../../../s/img/icons/beauty/tanning.svg");
}

.sub-icon-wellness-alternative {
    background-color: #f8c2a8;
    background-image: url("../../../s/img/icons/wellness/alternative.svg");
}

.sub-icon-wellness-education {
    background-color: #f8c2a8;
    background-image: url("../../../s/img/icons/wellness/education.svg");
}

.sub-icon-wellness-healthy {
    background-color: #f8c2a8;
    background-image: url("../../../s/img/icons/wellness/healthy.svg");
}

.sub-icon-wellness-massage {
    background-color: #f8c2a8;
    background-image: url("../../../s/img/icons/wellness/massage.svg");
}

.sub-icon-wellness-nutrition {
    background-color: #f8c2a8;
    background-image: url("../../../s/img/icons/wellness/nutrition.svg");
}

.sub-icon-wellness-ondemand {
    background-color: #f8c2a8;
    background-image: url("../../../s/img/icons/wellness/ondemand.svg");
}

.sub-icon-wellness-physiotherapy {
    background-color: #f8c2a8;
    background-image: url("../../../s/img/icons/wellness/physiotherapy.svg");
}

.sub-icon-wellness-retreats {
    background-color: #f8c2a8;
    background-image: url("../../../s/img/icons/wellness/retreats.svg");
}

.sub-icon-wellness-sauna {
    background-color: #f8c2a8;
    background-image: url("../../../s/img/icons/wellness/sauna.svg");
}

.sub-icon-wellness-spa {
    background-color: #f8c2a8;
    background-image: url("../../../s/img/icons/wellness/spa.svg");
}